import img1 from "../img/img1.png";
import img2 from "../img/img2.png";
import img3 from "../img/img3.png";
import img4 from "../img/img4.jpg";

export const Followers = [
  { name: "Bill Gates", username: "billy", img: img1 },
  { name: "Elon Musk", username: "did_you_say_tt?", img: img2 },
  { name: "Warren Buffett", username: "coca_sponsor", img: img3 },
  { name: "Gisele Bündchen", username: "gisele", img: img4 },
];