import postPic1 from '../img/postpic1.jpg'
import postPic2 from '../img/postpic2.jpg'
import postPic3 from '../img/postpic3.JPG'

export const PostsData = [
    {
        img: postPic1,
        name: 'Lily Martins',
        desc: "Beautiful!! 🐶 ",
        likes: 2303,
        liked: true
    },
    {
        img: postPic2,
        name: 'Maryam',
        desc: "👏👏👏👏👏👏",
        likes: 1464,
        liked: false

    },
    {
        img:postPic3,
        name: "Eliott DY",
        desc: "😍😍😍",
        likes: 2678,
        liked: false
    }
]