export const TrendData= [
    {
      name: "ReactJS",
      shares: 97,
    },
    {
      name: "Git",
      shares: 80.5,
    },
    {
      name: "Scss",
      shares: 75.5,
    },
    {
      name: "React",
      shares: 72,
    },
    {
      name: "JS",
      shares: 71.9,
    },
    {
      name: "Angular",
      shares: 20,
    },
  ];